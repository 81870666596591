










































import Vue from 'vue'
import Component from 'vue-class-component'
import { Validate } from 'vuelidate-property-decorators';
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import Cookies from "js-cookie";


@Component
export default class LoginView extends Vue {
  showPass = false
  loading = false
  showError = false

  @Validate({ required })
  login: string | null = ''

  @Validate({ required })
  pass: string | null = ''

  get loginErrorsMessages (): string[] {
    const errors: string[] = []
    if (!this.$v.login.$dirty) return errors
    !this.$v.login.required && errors.push('Обязательное поле')
    return errors
  }

  get passErrorsMessages (): string[] {
    const errors: string[] = []
    if (!this.$v.pass.$dirty) return errors
    !this.$v.pass.required && errors.push('Обязательное поле')
    return errors
  }

  async doLogin (): Promise<void> {
    this.$v.$touch()
    if (this.$v.$invalid) return
    this.showError = false
    this.loading = true
    const response = await axios.post('/login/', {
      login: this.login,
      pass: this.pass
    })
    this.loading = false
    if (response.status === 200) {
      if (response.data === 'wrong') {
        this.showError = true
      } else {
        Cookies.set('token', response.data.token)
        console.log(response.data.isAdmin)
        if (response.data.isAdmin) {
          await this.$router.push({name: 'admin.clients'})
        } else {
          await this.$router.push({name: 'cabinet'})
        }
      }
    }
  }
}
